.App {
  text-align: center;
  background-color: #130708;
}

.container {
  position: static;
  place-content: center;
  display: flex;
  height: min(100dvw, 100dvh);
}

.App-coming {
  pointer-events: none;
}

@media only screen and (max-width: 730px) {
  .container {
    height: 100vh;
  }
  .App-coming {
    width: -webkit-fill-available;
  }
}